<template>
  <VersionSelect v-if="plp_task_id" fluid>
    <template #content="{ data, readonly, onChange, onBlur, onCreate }">
      <ExpAssessment
        :value="data"
        @change="onChange"
        :readonly="readonly"
        :onCreate="onCreate"
        @blur="onBlur"
      />
    </template>
  </VersionSelect>
</template>

<script>
import VersionSelect from '@/components/VersionSelect'

import { mapGetters } from 'vuex'
import ExpAssessment from '@/components/expAssessment/expAssessment.vue'

export default {
  components: { ExpAssessment, VersionSelect },
  data: () => ({}),
  computed: {
    ...mapGetters(['plp_task_id']),
  },
}
</script>
